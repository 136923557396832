<template>
  <div>
    <v-toolbar dense class="rounded">
      <v-btn icon class="hidden-xs-only" @click="$router.push('/casino')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title
        >TÉRMINOS Y CONDICIONES ¡Slots Clásicos vs Modernos!</v-toolbar-title
      >

      <v-spacer></v-spacer>
    </v-toolbar>

    <div class="mt-3 rounded white pa-3 text-justify">
      <iframe
        src="https://tar.la/dominio/tr/promopragmatic.html"
        :style="
          size < 960
            ? 'width: 100%; height: calc(' + this.screenHeight * 0.84 + 'px)'
            : 'width: 100%; height: calc(100vh - 95px)'
        "
        frameborder="0"
      ></iframe>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.p {
  background-color: #fff;
}
.tabl {
  border: 1px solid #c0c0c0;
  border-collapse: collapse;
  padding: 5px;
  background: white;
  color: black;
  border-radius: 4px !important;
  width: 100%;
}
.tabd {
  border: 1px solid #c0c0c0;
  border-collapse: collapse;
  padding: 10px;
  background: white;
  color: black;
  border-radius: 4px !important;
  width: 100%;
}
.tabl th {
  border: 1px solid #c0c0c0;
  padding: 5px;
  border-radius: 4px !important;
  font-size: 12px !important;
}
.tabl td {
  border: 1px solid #c0c0c0;
  padding: 5px;
  border-radius: 4px !important;
}
</style>
